import React from 'react';

import Image from '~/components/image/Image';
import { IPopularBoxArticle } from '~/interfaces/components/PopularBox';

/**
 * ArticleList: Single row for each article
 */
interface IArticleList {
  article: IPopularBoxArticle;
  index: number;
}

const ArticleList: React.FC<IArticleList> = ({ article, index }) => {
  let image: JSX.Element | null = null;

  if (index === 0 && article.image) {
    image = (
      <div className="popular-box__article-list__image-wrapper">
        <Image className="popular-box__article-list__image" {...article.image} />
      </div>
    );
  }

  return (
    <li className="popular-box__article-list">
      <div className="popular-box__article-list__container">
        {image && (
          <a
            title={article.heading}
            className="popular-box__article-list__link"
            href={article.href}
            data-analytics-id="popular-list"
          >
            {image}
          </a>
        )}
        <div
          className="popular-box__article-list__content"
          style={!image ? { position: 'unset' } : {}}
        >
          <div className="popular-box__article-list__number-text">{index + 1}</div>
          <div className="popular-box__article-list__heading">
            <a
              className="popular-box__article-list__link"
              href={article.href}
              data-analytics-id="popular-list"
            >
              {article.heading}
            </a>
          </div>
        </div>
      </div>
    </li>
  );
};

/**
 * ArticlesList: List of articles
 */
interface IArticleLists {
  articles: IPopularBoxArticle[];
  activeLabel: number;
  indexNumber: number;
}

const ArticlesList: React.FC<IArticleLists> = ({ activeLabel, articles, indexNumber }) => {
  const popListClassName: string =
    indexNumber === activeLabel ? 'popular-box__articles-list--active' : '';

  const articlesComponent = articles.map((article, index) => (
    <ArticleList article={article} index={index} key={`articles-list-${article.heading}`} />
  ));

  return <ol className={`popular-box__articles-list ${popListClassName}`}>{articlesComponent}</ol>;
};

export default ArticlesList;
